exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portal-redirect-tsx": () => import("./../../../src/pages/portal-redirect.tsx" /* webpackChunkName: "component---src-pages-portal-redirect-tsx" */),
  "component---src-pages-sesion-expirado-tsx": () => import("./../../../src/pages/sesion-expirado.tsx" /* webpackChunkName: "component---src-pages-sesion-expirado-tsx" */),
  "component---src-templates-login-tsx": () => import("./../../../src/templates/login.tsx" /* webpackChunkName: "component---src-templates-login-tsx" */),
  "component---src-templates-redirect-home-tsx": () => import("./../../../src/templates/redirect-home.tsx" /* webpackChunkName: "component---src-templates-redirect-home-tsx" */),
  "component---src-templates-redirect-tsx": () => import("./../../../src/templates/redirect.tsx" /* webpackChunkName: "component---src-templates-redirect-tsx" */)
}

