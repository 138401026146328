import React, { useEffect, useState } from 'react';
import AppContext from './AppContext';
import { AvailableChannels } from '../../services/CustomerServices';
import { setItemToLocalStorage } from '../../common/Storage';
import { navigate } from 'gatsby';

const AppProvider = ({ children }: { children: any }) => {
  const [availableChannels, setAvailableChannels] = useState(null);
  const [urlRedirect, setUrlRedirect] = useState('');
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      const transactionId = params.get('transactionId');
      const url_redirect = params.get('url_redirect');
      if (url_redirect) {
        setUrlRedirect(url_redirect);        
        setTimeout(() => {
          navigate('');
        }, 500);
      }
      if (transactionId) {
        setItemToLocalStorage('transactionId', 5, transactionId);
        setTimeout(() => {
          navigate('');
        }, 500);
      }
    }

    AvailableChannels()
    .then(({ data }) => {
      const channels = data
        .reduce((obj: object, key: string) => ({ ...obj, [key]: true }), {});
      setAvailableChannels(channels);
    });
  }, []);

  return (
    <AppContext.Provider value={{ availableChannels, setAvailableChannels, urlRedirect, setUrlRedirect }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
