import axios, {
  Method,
  AxiosRequestConfig,
} from "axios";

const API_KEY_LOGIN_MANAGER = `${process.env.LM_APIKEY}`;

// TODO: make "any" reference a dynamic type
type RequestType = (
  url: string,
  { method, data }?: { method: Method; data: object }
) => Promise<any>;

const mainAxios = (config: AxiosRequestConfig) => axios(config)
  .then((res) => res)
  .catch((err) => {
    return Promise.reject(err);
  });

const Request: RequestType = (
  url,
  { method, data } = { method: "GET", data: {} }
) => {
  const config: AxiosRequestConfig = {
    url,
    method,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "lm-api-key": API_KEY_LOGIN_MANAGER,
    },
    ...{ data: JSON.stringify(data) },
    validateStatus: () => true,
  };

  return mainAxios(config);
};

const RequestMS: RequestType = (
  url,
  { method, data } = { method: "GET", data: {} }
) => {
  const config: AxiosRequestConfig = {
    url,
    method,
    ...{ data: JSON.stringify(data) },
    validateStatus: () => true,
  };

  return mainAxios(config);
};

export { Request as default, RequestMS };
