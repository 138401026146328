import {
  ChannelRedirectionType,
  UniverseType,
} from '../components/Login/@types';
import { navigate as GatsbyNavigate } from 'gatsby';
import { delItemFromLocalStorage, getItemFromLocalStorage } from './Storage';

const isBrowser = () => typeof window !== 'undefined';

const navigate = (url: string, options?: object) => {
  if (isBrowser()) GatsbyNavigate(url, { ...(options && { ...options}) });
};

const localTokenHandler = {
  name: 'tkn',

  set(tkn: string) {
    if (isBrowser()) {
      window.localStorage.setItem(this.name, tkn);
    }
  },

  get() {
    if (isBrowser()){
      const { name } = this;
      const tkn = window.localStorage.getItem(name) || '';
      window.localStorage.removeItem(name);
      return tkn;
    }
  }
}

const channelRedirect = ({
  channel,
  universe,
  urlRedirect
}: {
  channel?: ChannelRedirectionType | 'cautivo' | 'bienvenidas',
  universe?: UniverseType,
  urlRedirect?: string,
} ) => {
  if (isBrowser()) {
    const goTo = (url: string) => window.open(url, '_self');
    const url_redirect = window.localStorage.getItem('url_redirect');
    window.localStorage.removeItem('url_redirect');
    const transactionId = getItemFromLocalStorage('transactionId');
    if(transactionId) delItemFromLocalStorage('transactionId');    

    if(urlRedirect) goTo(urlRedirect)
    else if (transactionId && transactionId.value !== '') {
      goTo(`${process.env.WOMPAY_CHANNEL_URL}/pay/${transactionId.value}`);
    } else if (channel === 'cautivo')
    goTo(`${process.env.MIWOM_CHANNEL_URL}/portal-cautivo`);
    else if (channel === 'bienvenidas')
      goTo(`${process.env.MIWOM_CHANNEL_URL}/consejos-bam`);
    else if (channel === 'store')
      goTo(`${process.env.STORE_CHANNEL_URL}`);
    else if (channel === 'movil' || universe === 'phone')
      goTo(`${process.env.MIWOM_CHANNEL_URL}`);
    else if (channel === 'bam' || universe === 'bam')
      goTo(`${process.env.BAM_CHANNEL_URL}`);
    else if (channel === 'womfibra' || universe === 'fiber')
      goTo(`${process.env.FIBER_CHANNEL_URL}`);
    else if (channel === 'womtv' || universe === 'tv')
      goTo(`${process.env.TV_CHANNEL_URL}`);
    else if (channel === 'miwom')
      goTo(`${process.env.MIWOM_CHANNEL_URL}${url_redirect}`);
  }
};

export { isBrowser, navigate, channelRedirect, localTokenHandler };
