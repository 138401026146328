import React from 'react';
import LayoutStyled from './styles/LayoutStyled';
import { ThemeProvider } from '@womcorp/wom-miwom-ui-kit/dist/@theme';
import App from '../App';

const Layout: React.FC = ({ children }) => {
  return (
    <ThemeProvider>
      <LayoutStyled>
        <App>
          {children}
        </App>
      </LayoutStyled>
    </ThemeProvider>
  );
};

export default Layout;

