import { isBrowser } from './Utils';

const ACCESS_TOKEN = 'sso_token';
const REFRESH_TOKEN = 'sso_refresh_token';

type SetOTPStorageType = ({
  access_token,
  refresh_token,
}: {
  access_token: string;
  refresh_token: string;
}) => void;

const SetOTPStorage: SetOTPStorageType = ({ access_token, refresh_token }) => {
  if (isBrowser()) {
    window.localStorage.setItem(ACCESS_TOKEN, access_token);
    window.localStorage.setItem(REFRESH_TOKEN, refresh_token);
  }
};

const GetOTPStorage = () => {
  if (isBrowser()) {
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    if (token)
      return {
        token,
        refreshToken: window.localStorage.getItem(REFRESH_TOKEN),
      };
  }
  return false;
};

export interface LocalStorageItem {
  value: string;
  exp: number;
  crt: number;
}

const getItemFromLocalStorage = (key: string): LocalStorageItem | null => {
  if (isBrowser()) {
    const item = window.localStorage.getItem(key);

    if (item) {
      const decodedItem = JSON.parse(window.atob(item));

      if (decodedItem.exp && Date.now() <= decodedItem.exp) {
        return decodedItem as LocalStorageItem;
      } else {
        window.localStorage.removeItem(key);
        return null;
      }
    }
  }

  return null;
};

const setItemToLocalStorage = (
  key: string,
  expirationMinutes: number = 0,
  value: string | null
): void => {
  if (isBrowser()) {
    const expiration =
      expirationMinutes > 0 ? Date.now() + expirationMinutes * 60 * 1000 : 0;
    const createdAt = Date.now();
    const objectToStore = { exp: expiration, value, crt: createdAt };
    const encodedObject = window.btoa(JSON.stringify(objectToStore));
    window.localStorage.setItem(key, encodedObject);
  }
};

const delItemFromLocalStorage = (key: string) => {
  if(isBrowser()){
    window.localStorage.removeItem(key);
  }
};

export {
  SetOTPStorage,
  GetOTPStorage,
  getItemFromLocalStorage,
  setItemToLocalStorage,
  delItemFromLocalStorage,
};
