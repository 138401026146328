import { createContext } from 'react';

type TypeInitialValues = {
  availableChannels: any,
  setAvailableChannels: (value: (((prevState: null) => null) | null)) => void,
  urlRedirect: any,
  setUrlRedirect: (value: (((prevState: string) => string) | string)) => void,

}

const initialValues: TypeInitialValues = {
  availableChannels: null,
  setAvailableChannels: () => {},
  urlRedirect: '',
  setUrlRedirect: () => {},
};

const AppContext = createContext(initialValues);

export default AppContext;
